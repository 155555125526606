/* eslint-disable react-refresh/only-export-components */
import React, { lazy } from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, createRoutesFromChildren, matchRoutes, RouterProvider, useLocation, useNavigationType } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from 'react-hot-toast';
import { queryClient, setApiBaseUrl, setAxiosAuthToken } from './utils/api';
import { TokenProvider } from './components/providers/tokenProvider';
import * as Sentry from "@sentry/react";

import './index.css';

setApiBaseUrl();
setAxiosAuthToken(localStorage.getItem('token') ?? '');

const Sjem = lazy(() => import('./pages/sjem'));
const RedirectToAccount = lazy(() => import('./pages/redirect'));

Sentry.init({
  dsn: "https://58961e432d39ee2896beb028c31215e9@o4507890071175168.ingest.de.sentry.io/4507890074779728",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([{
  path: "/sjem",
  element: <Sjem />
}, {
  path: "/sjem/:module/:lesson",
  element: <Sjem />
}, {
  path: "*",
  element: <RedirectToAccount />
}]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <TokenProvider>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <Toaster position="bottom-right" toastOptions={{
          duration: 5000,
          style: {
            background: 'var(--color-white)',
            color: 'var(--color-black)',
            borderRadius: '999px',
          },
          iconTheme: {
            primary: 'var(--color-progress)',
            secondary: 'var(--color-white)',
          },
        }} />
      </QueryClientProvider>
    </TokenProvider>
  </React.StrictMode>,
)
