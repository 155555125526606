import { QueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { Student } from '../types/student';
import { LessonContent } from '../types/lesson';
import { NavigationChapter } from '../types/navigation';
import { Env, getEnv } from './env';

export const LOCAL_API_URL = 'http://localhost:8080';
export const PROD_API_URL = 'https://rootandbloom.studio';

const getBaseUri = () => {
  const env = getEnv();

  let url = PROD_API_URL;
  if (env === Env.localhost) {
    url = LOCAL_API_URL;
  }

  return url;
};

export const setApiBaseUrl = () => {
  axios.defaults.baseURL = `${getBaseUri()}/wp-json/courses/v1`;
};

export const setAxiosAuthToken = (accessToken: string) => {
  // Sets the axios auth header for all requests
  axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  axios.defaults.withCredentials = true;
};

export const clearAxiosAuthToken = () => {
  // Sets the axios auth header for all requestss
  axios.defaults.headers.common['Authorization'] = undefined;
};

export const getAccountUrl = () => {
  return `${getBaseUri()}/account`;
};

export const getLibraryUrl = () => {
  return `${getBaseUri()}/account/orders/`;
}

export const getLogoutUrl = () => {
  return `${getBaseUri()}/account/customer-logout/`;
}

export const queryClient = new QueryClient();

export const getNavigation = (slug: string) => axios.get<NavigationChapter[]>(`/${slug}/navigation`).then(({ data }) => data);

export const getContent = (slug: string, lessonId: number) => axios.get<LessonContent>(`/${slug}/${lessonId}/content`).then(({ data }) => data);

export const getStudent = (slug: string) => axios.get<Student>(`/${slug}/student`).then(({ data }) => data);

interface AnswerBody {
  id: string;
  value: string;
}

export const postAnswer = (slug: string, lessonId: number, data: AnswerBody) => axios.post<Student>(`/${slug}/${lessonId}/answer`, data).then(({ data }) => data);

export const postComplete = (slug: string, lessonId: number) => axios.post<Student>(`/${slug}/${lessonId}/complete`, {}).then(({ data }) => data);

export const postSubmitCourse = (slug: string, confirmed: boolean) => axios.post(`/${slug}/submit`, {confirmed}).then(({ data }) => data);
