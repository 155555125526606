export enum Env {
    localhost = "localhost",
    production = "production",
}

export function getEnv(): Env {
    const url = window.location.origin

    if (url.includes('localhost')) {
        return Env.localhost;
    }

    return Env.production
}
