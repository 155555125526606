import { createContext, PropsWithChildren, useMemo } from "react";
import { getAccountUrl, setAxiosAuthToken } from "../../utils/api";

export const TokenContext = createContext({});

export const NAME_TOKEN = 'token';

// set token via ?token={bearer}
export const TokenProvider = ({ children }: PropsWithChildren) => {

    const token = useMemo<string>(() => {
        const params = new URLSearchParams(window.location.search);
        const tokenViaUrl = params.get(NAME_TOKEN);
        if (tokenViaUrl) {
            window.location.search = '';
            localStorage.setItem(NAME_TOKEN, tokenViaUrl);
            return tokenViaUrl;
        }

        const tokenFromLocalStorage = localStorage.getItem(NAME_TOKEN) ?? '';
        if (tokenFromLocalStorage) {
            setAxiosAuthToken(tokenFromLocalStorage);
            return tokenFromLocalStorage;
        }
        
        return '';
    }, []);
    
    setAxiosAuthToken(token);

    return <TokenContext.Provider value={{ token }}>
        {!token && <div className="relative w-full h-screen flex items-center justify-center">
            <div className="text-center space-y-5">
                <h1 className="text-[3rem] font-headings">Er is wat fout gegaan...</h1>
                <p>Ga terug naar je account om het opnieuw te proberen</p>
                <div className="inline-block pt-5">
                    <a href={getAccountUrl()} className="btn">Ga terug naar je account</a>
                </div>
            </div>
        </div>}
        {token && children}
    </TokenContext.Provider>
}
